import Moment from 'moment'
import {mapGetters} from 'vuex'
export default {
	data() {
		return {
			queryForm: {
				category: '',
				channel: '',
				rule: '',
				date: [
					Moment().startOf('day').format('x'),
					Moment().endOf('day').format('x')
				],
				spu: ''
			},
			q: {
				channel: '',
				createdAtMin: Moment().startOf('day').format('x'),
				createdAtMax: Moment().endOf('day').format('x')
			},
			tableData: [],
			fetchOnCreated: false,
			orderBy: 'tps.sales_total desc',
			namespace: 'fb-carousel-ads'
		}
	},
	computed: {
		...mapGetters({
			orderBys: `fb-carousel-ads/orderBys`
		}),
		defaultSort() {
			if (this.orderBy === '') return {prop: null, order: null}
			const orderBy = this.orderBys.find(v => v.key === this.orderBy)
			return orderBy || {prop: 'tps.sales_total', order: 'descending'}
		}
	},
	created() {
		const {start, end, cate, rule, spus, orderBy} = this.$route.query
		if (!!start && !!end && /\d+/.test(start) && /\d+/.test(end) && Number(start) < Number(end)) {
			this.queryForm.date = [start, end]
			this.q.createdAtMin = start
			this.q.createdAtMax = end
		}
		if (!!cate) {
			this.queryForm.category = cate
			this.q.category = cate
		}
		if (!!rule) {
			this.queryForm.rule = rule
			this.q.rule = rule
		}
		if (!!spus) {
			this.queryForm.spu = spus
			this.q.spus = spus
		}
		const _orderBy = this.orderBys.find(v => v.key === orderBy)
		if (_orderBy) {
			this.orderBy = _orderBy.key
		} else if (orderBy === '') {
			this.orderBy = orderBy
		}
	},
	methods: {
		searchEvent() {
			const spus = this.queryForm.spu.match(/[\w-]+/g)
			const cate = this.queryForm.category
			this.q = {
				createdAtMin: this.queryForm.date[0],
				createdAtMax: this.queryForm.date[1],
				channel: this.queryForm.channel,
				spus,
				category: cate,
				rule: this.queryForm.rule
			}
			const decodedCate = window.encodeURIComponent(cate.trim())
			const orderBy = window.encodeURIComponent(this.orderBy)
			const search = `start=${this.queryForm.date[0]}&end=${this.queryForm.date[1]}&rule=${this.queryForm.rule}&cate=${decodedCate}&spus=${(spus || []).join(',')}&orderBy=${orderBy}`
			history.pushState(this.q, '', `${window.location.pathname}?${search}`)
			this.page.current = 1
			this.getList()
		},
		handleList(content) {
			this.tableData = content.map(v => {
				let originPic = undefined
				if (v.picManual) {
					originPic = false
				} else if (v.picAuto) {
					originPic = true
				}
				return {
					...v,
					originPic
				}
			})
		},
		transitionToPage(pageName) {
			const date = this.queryForm.date
			const spus = this.queryForm.spu.match(/[\w-]+/g)
			const query = {
				start: date[0],
				end: date[1],
				cate: this.queryForm.category.trim(),
				rule: this.queryForm.rule,
				spus: (spus || []).join(','),
				orderBy: this.orderBy
			}
			this.nav_replace(pageName, {}, query)
		}
	}
}